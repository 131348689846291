import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Flight } from "@flightpath/api-types";
import { EmailTemplate } from "@flightpath/ui";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { LoaderService } from "../shared/loader.service";
import {
  AlertOptions,
  CreateRFQForItineraryOptions,
  EmailOutreach,
  EmailOutreachResponse,
  EmailTemplateResponse,
  FlightDetailsResponse,
  PhoneOutreach,
  PhoneOutreachResponse
} from "./model";
/* const mockReceivedQuotes = [
  {
    id: "Quote-1",
    TailNr: "G-FXCR",
    Operator: "Sirio",
    Type: "400XTi",
    Base: "FLOAT",
    Amenities: {
      Lavatory: true
    },
    Price: 7000,
    TotalPrice: 7000
  },
  {
    id: "Quote-2",
    TailNr: "G-LFBD",
    Operator: "Centreline",
    Type: "CJ2",
    Base: "EBBR",
    Amenities: {
      Lavatory: true,
      OwnerApprovalRequired: true
    },
    Price: 11190,
    TotalPrice: 1190
  },
  {
    id: "Quote-3",
    TailNr: "N533MP",
    Operator: "Sky Team EU",
    Type: "G400",
    Base: "LIRO",
    Amenities: {
      Pets: true,
      OwnerApprovalRequired: true
    },
    Price: 11195,
    TotalPrice: 11195
  },
  {
    id: "Quote-4",
    TailNr: "N523MP",
    Operator: "Top Flight Aviation",
    Type: "CJ3",
    Base: "LIPF",
    Amenities: {
      Lavatory: true,
      Pets: true,
      OwnerApprovalRequired: true,
      HeatingElement: true
    },
    Price: 12000,
    TotalPrice: 12000
  },
  {
    id: "Quote-5",
    TailNr: "N587MP",
    Operator: "No Limit Aviation",
    Type: "G450",
    Base: "LFMN",
    Amenities: {
      HeatingElement: true
    },
    Price: 12050,
    TotalPrice: 12050
  }
]; */

@Injectable()
export class FlightDetailsService {
  constructor(private http: HttpClient, private loaderService: LoaderService) {}

  getDetails = (referenceId: string): Observable<Flight> => {
    this.loaderService.show();
    return this.http
      .get<FlightDetailsResponse>(`flight/flight-details/${referenceId}`)
      .pipe(
        map(res => {
          this.loaderService.hide();
          return res.result;
        })
      );
  };

  emailTemplateCache: any = {};
  getEmailTemplate(name: string): Observable<EmailTemplate> {
    if (this.emailTemplateCache[name]) return of(this.emailTemplateCache[name]); //.pipe(delay(1));
    this.loaderService.show();
    return this.http
      .get<EmailTemplateResponse>(`messaging/email-template/${name}`)
      .pipe(
        map(res => {
          this.emailTemplateCache[name] = res.result.Content;
          this.loaderService.hide();
          return this.emailTemplateCache[name];
        })
      );
  }

  sendEmailOutreach(email: EmailOutreach) {
    this.loaderService.show();
    return this.http
      .post<EmailOutreachResponse>(
        "flight/inquiry-contact-event/send-email",
        email
      )
      .pipe(
        map(res => {
          this.loaderService.hide();
          return res.result;
        })
      );
  }

  sendPhoneOutreach(phone: PhoneOutreach) {
    this.loaderService.show();
    return this.http
      .post<PhoneOutreachResponse>(
        "flight/inquiry-contact-event/create-phone-outreach",
        phone
      )
      .pipe(
        map(res => {
          this.loaderService.hide();
          return res.result;
        })
      );
  }

  saveAlertOptions(alertOptions: AlertOptions) {
    this.loaderService.show();
    return this.http.post("flight/inquiry-details", alertOptions).pipe(
      map(() => {
        this.loaderService.hide();
      })
    );
  }

  getPricing(inquiryId: string) {
    return this.http
      .post<any>(`flight/inquiries/${inquiryId}/pricing`, {})
      .pipe(
        map(res => {
          this.loaderService.hide();
          return res.result;
        })
      );
  }

  CreateRFQForItineraryOptions(rfq: CreateRFQForItineraryOptions) {
    this.loaderService.show();
    return this.http
      .post<any>(`flight/inquiries/${rfq.inquiryId}/rfq`, rfq)
      .pipe(
        map(res => {
          this.loaderService.hide();
          return res.result;
        })
      );
  }

  getTailDetails(pricingOption) {
    let tailNumber = pricingOption.tail.number;
    this.loaderService.show();
    return this.http
      .get<FlightDetailsResponse>(`flight/tails/${tailNumber}`)
      .pipe(
        map(res => {
          this.loaderService.hide();
          return res.result;
        })
      );
  }

  sendQuoteEmail(email) {
    this.loaderService.show();
    return this.http.post<any>("messaging/send-email", email).pipe(
      map(() => {
        this.loaderService.hide();
      })
    );
  }

  /*   registerEmail() {
    let data = {
      email: ""
    };
    return this.http.post<any>(`messaging/add-email-identity`, data).pipe(
      map(res => {
        return res.result;
      })
    );
  } */

  getReceivedQuotes(inquiryId) {
    this.loaderService.show();
    return this.http.post<any>(`flight/inquiries/${inquiryId}/quotes`, {}).pipe(
      map(res => {
        this.loaderService.hide();
        return res.result;
      })
    );
  }
}
