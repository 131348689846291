import { InquiryOutput } from "@flightpath/api-types";
import { Sorting } from "@flightpath/ui";
import { Moment } from "moment";

export interface FlightDataResponse {
  input: any;
  result: InquiryOutput[];
}

export enum FlightStatus {
  DELETED = "Deleted", // === End
  AWAITING_AUTHORIZATION = "Awaiting Authorization",
  REJECTED = "Rejected",
  ENQUIRY = "Enquiry",
  ON_HOLD = "On Hold",
  TENDERED = "Tendered",
  CANCELLED = "Cancelled",
  CONFIRMED = "Confirmed",
  ACTIVE = "Active",
  FLOWN = "Flown"
}

export interface FlightStatusData {
  all: number;
  authorization: number;
  holding: number;
  tendered: number;
  booked: number;
  awaiting: number;
  cancelled: number;
  active: number;
  saved: number;
}

export interface FlightListFilter {
  startDate?: Date | Moment;
  endDate?: Date | Moment;
  tripStatus?: string;
  search?: string;
  isVisible?: boolean;
}

export interface FlightList {
  items: InquiryOutput[];
  filteredItems: InquiryOutput[];
  filter?: FlightListFilter;
  sorting: Sorting;
  loading: boolean;
  error: boolean;
}

export interface FlightListError {
  status: string;
}

export function initialFlightList(): FlightList {
  return {
    items: [],
    filteredItems: [],
    filter: {
      isVisible: true
    },
    sorting: null,
    loading: false,
    error: false
  };
}
