import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";

export function structToDateTime(date, time) {
  if (!date || !time) return null;
  return moment()
    .date(date.day)
    .month(date.month - 1)
    .year(date.year)
    .hour(time.substring(0, 2))
    .minute(time.substring(3, 5))
    .format();
}

export function structToDate(date) {
  if (!date) return null;
  return moment()
    .date(date.day)
    .month(date.month - 1)
    .year(date.year);
}

export function structFromDate(date) {
  if (!date) return null;
  return {
    day: date.date(),
    month: date.month() + 1,
    year: date.year()
  };
}

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (!value) return null;
    const date = moment(value, "MM/DD/YYYY");
    if (!date.isValid()) return null;
    return structFromDate(date);
  }

  format(date: NgbDateStruct): string {
    if (!date) return "";
    return structToDate(date).format("MM/DD/YYYY");
  }
}
